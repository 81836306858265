import React from "react";
import { FONT_SIZES } from "../../styles";

const AboutMe = () => {
  return (
    <div style={{ fontSize: FONT_SIZES.s }}>
      <div style={{ marginBottom: "4vh" }}>
        🌭 Chicago based software engineer
      </div>
      <div style={{ marginBottom: "4vh" }}>
        🎼 Former music educator turned mobile app developer turned Googler
      </div>
      <div style={{ marginBottom: "4vh" }}>
        🎳 Likes engineering web solutions, making the internet really fast, and
        bowling
      </div>
    </div>
  );
};
export default AboutMe;
