export interface StyleSheet {
  [key: string]: React.CSSProperties | StyleSheet;
}

export const FONT_FAMILY = "Raleway, Tahoma, sans-serif";

export const FONT_SIZES = {
  root: "62.5%", //62.5% of 16px = 10px
  xxxl: "3rem",
  xxl: "2.8rem",
  xl: "2.4rem",
  l: "2.1rem",
  m: "1.7rem",
  s: "1.4rem",
  xs: "1.1rem",
  xxs: "0.9rem",
};

export const SPACER = {
  height: "5vh",
};

export const SPACING = {
  small: "30px",
};

export const COLORS = {
  charcoal: "#36454F",
  serenity: "#8CA6B5",
  khaki: "#CBBDAE",
  honeysuckle: "#B06153",
};

export const TextStyles: StyleSheet = {
  base: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZES.m,
    color: "white",
    letterSpacing: "1.3px",
  },
  details: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZES.xs,
    color: "black",
    letterSpacing: 1.3,
    fontWeight: 200,
  },
  nav: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZES.s,
    fontWeight: 300,
    color: "white",
  },
};
