import React, { useState } from "react";
import { TextStyles, FONT_SIZES, SPACING } from "../styles";
import { useMediaQuery } from "../utils";
import "./nav.css";

const Navbar = () => {
  const [showMobileDrop, setShowMobileDrop] = useState(false);
  const isMobile = useMediaQuery();

  const handleClick = () => {
    setShowMobileDrop(!showMobileDrop);
  };

  const renderMenu = () => (
    <>
      <a className="socialOuter" href="mailto:nicholascsward@gmail.com">
        Email
      </a>

      <SocialIcon type="LinkedIn" />
      <SocialIcon type="Github" />
    </>
  );

  return (
    <section className="navParent" style={{ minHeight: FONT_SIZES.xxxl }}>
      <nav
        style={{
          ...TextStyles.nav,
          minHeight: FONT_SIZES.xxxl,
          ...(isMobile && { height: "7vh", minHeight: FONT_SIZES.xxl }),
        }}
        className="navOuter"
      >
        {isMobile ? (
          <div
            className="navInner"
            style={{
              paddingRight: SPACING.small,
              paddingLeft: SPACING.small,
              fontSize: FONT_SIZES.s,
            }}
          >
            Niko Sward
            <div style={{ display: "flex" }} onClick={handleClick}>
              <img
                // hamburger.svg
                src={
                  "data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' ?%3e%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 75 75' width='30' height='30' fill='white'%3e%3crect width='100' height='15'%3e%3c/rect%3e%3crect y='30' width='100' height='15'%3e%3c/rect%3e%3crect y='60' width='100' height='15'%3e%3c/rect%3e%3c/svg%3e"
                }
                alt="menu icon"
              />
            </div>
          </div>
        ) : (
          <div
            className="navInner"
            style={{ paddingRight: SPACING.small, paddingLeft: SPACING.small }}
          >
            Niko Sward - Software Engineer
            <div style={{ display: "flex", fontSize: FONT_SIZES.xs }}>
              {renderMenu()}
            </div>
          </div>
        )}
      </nav>
      {showMobileDrop && (
        <section
          className="mobileDrop"
          style={{ fontSize: FONT_SIZES.xxs }}
          onClick={handleClick}
        >
          {renderMenu()}
        </section>
      )}
    </section>
  );
};

const SocialIcon = ({ type }) => {
  return (
    <a href={SOCIALS[type].href} className="socialOuter">
      <img src={SOCIALS[type].src} alt={`${type} icon`} className="logo" />
    </a>
  );
};

const SOCIALS = {
  LinkedIn: {
    href: "https://linkedin.com/in/nsward",
    src: "data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' ?%3e%3csvg height='72' viewBox='0 0 72 72' width='72' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath d='M36,72 L36,72 C55.882251,72 72,55.882251 72,36 L72,36 C72,16.117749 55.882251,-3.65231026e-15 36,0 L36,0 C16.117749,3.65231026e-15 -2.4348735e-15,16.117749 0,36 L0,36 C2.4348735e-15,55.882251 16.117749,72 36,72 Z' fill='%23007EBB'/%3e%3cpath d='M59,57 L49.959375,57 L49.959375,41.6017895 C49.959375,37.3800228 48.3552083,35.0207581 45.0136719,35.0207581 C41.3785156,35.0207581 39.4792969,37.4759395 39.4792969,41.6017895 L39.4792969,57 L30.7666667,57 L30.7666667,27.6666667 L39.4792969,27.6666667 L39.4792969,31.6178624 C39.4792969,31.6178624 42.0989583,26.7704897 48.3236979,26.7704897 C54.5455729,26.7704897 59,30.5699366 59,38.4279486 L59,57 Z M20.372526,23.8257036 C17.4048177,23.8257036 15,21.4020172 15,18.4128518 C15,15.4236864 17.4048177,13 20.372526,13 C23.3402344,13 25.7436198,15.4236864 25.7436198,18.4128518 C25.7436198,21.4020172 23.3402344,23.8257036 20.372526,23.8257036 Z M15.8736979,57 L24.958724,57 L24.958724,27.6666667 L15.8736979,27.6666667 L15.8736979,57 Z' fill='%23FFF'/%3e%3c/g%3e%3c/svg%3e",
  },
  Github: {
    href: "https://github.com/theneekz",
    src: "data:image/svg+xml;charset=UTF-8,%3csvg width='98' height='96' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z' fill='white'/%3e%3c/svg%3e",
  },
};

export default Navbar;
