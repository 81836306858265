import React, { useState } from "react";
import { FONT_SIZES } from "../../styles";
import fscreen from "fscreen";
import "./projects.css";
import { useMediaQuery } from "../../utils";

const spaceCampImg = require("../../images/spaceCamp.png");

const Projects = ({ style: styleProps = {}, className = "" }) => {
  const isMobile = useMediaQuery();
  const [hasPhaserLoaded, setHasPhaserLoaded] = useState(false);
  const phaserRef = React.useRef<HTMLDivElement>(null);

  const importPhaser = async () => {
    await import("./Phaser/config");
    setHasPhaserLoaded(true);
  };

  const toggleFullscreen = async () => {
    if (!fscreen.fullscreenElement) {
      fscreen.requestFullscreen(phaserRef.current);
    } else {
      fscreen.exitFullscreen();
    }
  };

  return (
    <div
      className={className + " project-container"}
      style={{ ...styleProps, fontSize: FONT_SIZES.s }}
    >
      <div style={{ fontWeight: "bold" }}>Contributions:</div>
      <a
        className="project-title"
        href="https://github.com/google-marketing-solutions/slide-starter"
        target="_blank"
        rel="noreferrer"
      >
        slide-starter
      </a>
      <a
        className="project-title"
        href="https://github.com/google-marketing-solutions/performance-psi-tracker"
        target="_blank"
        rel="noreferrer"
      >
        Performance PSI tracker
      </a>
      <a
        className="project-title"
        href="https://github.com/google-marketing-solutions/tagspeed_audit"
        target="_blank"
        rel="noreferrer"
      >
        tagspeed_audit
      </a>
      <a
        className="project-title"
        href="https://support.cvent.com/s/communityarticle/Meet-Our-Attendee-Hub-Event-App"
        target="_blank"
        rel="noreferrer"
      >
        Cvent Attendee Hub
      </a>
      <a
        className="project-title"
        href="https://www.redfoundry.com/portfolio/bellxcel/"
        target="_blank"
        rel="noreferrer"
      >
        bellxcel
      </a>
      <a
        className="project-title"
        href="https://www.redfoundry.com/portfolio/galaxy-digital/"
        target="_blank"
        rel="noreferrer"
      >
        Galaxy Digital
      </a>
      <a
        className="project-title"
        href="https://www.redfoundry.com/portfolio/skillin/"
        target="_blank"
        rel="noreferrer"
      >
        skillin
      </a>
      <a
        className="project-title"
        href="https://www.redfoundry.com/portfolio/lsat-angel/"
        target="_blank"
        rel="noreferrer"
      >
        LSAT Angel
      </a>
      <div style={{ fontWeight: "bold", marginTop: "50px" }}>Just for fun:</div>
      <a
        className="project-title"
        href="https://siqbeets.com/beetmaker2"
        target="_blank"
        rel="noreferrer"
      >
        Siqbeets
      </a>
      <div className="project-title">Space Camp</div>
      <div
        ref={phaserRef}
        className="game-container"
        style={{
          cursor: "pointer",
          ...(!hasPhaserLoaded && {
            background: `center / contain no-repeat url(${spaceCampImg})`,
          }),
        }}
        onClick={async () => {
          if (!hasPhaserLoaded) {
            if (
              isMobile &&
              DeviceOrientationEvent &&
              typeof DeviceOrientationEvent["requestPermission"] === "function"
            ) {
              await DeviceOrientationEvent["requestPermission"]();
            }
            importPhaser();
          }
          toggleFullscreen();
        }}
      >
        {!hasPhaserLoaded && (
          <div>
            CICK TO START NEW GAME
            <div>Dodge cubes to survive</div>
          </div>
        )}
        <div id="phaser-container"></div>
      </div>
    </div>
  );
};

export default Projects;
