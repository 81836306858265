import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./nav";
import { TextStyles } from "../styles";
import Home from "./Home/home";
import "./app.css";

function App() {
  return (
    <div style={TextStyles.base}>
      <Navbar />
      <Router>
        <div className="main">
          <Switch>
            <Route path="/" component={Home} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
