import React, { useRef } from "react";
import { FONT_SIZES } from "../../styles";
import { useWindowDimensions, useIsVisible, useMediaQuery } from "../../utils";
import "./experience.css";

const sections = [
  {
    title: "Music and teaching",
    details: [
      "Bachelor in Music Performance: Classical Guitar",
      "Radio station events coordinator & K-12 guitar teacher",
    ],
  },
  {
    title: "Tech education",
    details: [
      <>
        {"Harvard's CS50x: Introduction to Computer Science: "}
        <a
          href="https://cs50.harvard.edu/certificates/c48e06fe-8708-4add-aaeb-8f9d985647b7"
          target="_blank"
          rel="noreferrer"
        >
          certificate
        </a>
      </>,
      "Fullstack Academy Full-time Bootcamp",
      <>
        {"Harvard's CS50p: Introduction to Programming with Python: "}
        <a
          href="https://cs50.harvard.edu/certificates/9a831fae-5cbf-49f6-a41a-260c67f88c66"
          target="_blank"
          rel="noreferrer"
        >
          certificate
        </a>
      </>,
    ],
  },
  {
    title: "Database Admin @ Optime Realty",
    details: [
      "Automated CRM tasks & sales tools workflows",
      "Built applications for internal data analysis",
    ],
  },
  {
    title: "Software Engineer I @ Red Foundry",
    details: [
      "Fullstack mobile & web developer for small to medium sized businesses",
      "Stack: React, React Native, Typescript, Redux, and a lot of AWS products",
    ],
  },
  {
    title: "Software Engineer II @ Cvent",
    details: [
      "Frontend mobile developer on a white-label Attendee Hub Event App",
      "Stack: React Native, Couchbase Server",
    ],
  },
  {
    title: "Solutions Engineer @ Google",
    details: [
      "Web developer for internal and external business organization stakeholders",
      "Stack: Angluar, Typescript, SQL",
    ],
  },
];

const Experience = () => {
  return (
    <div className="experience-container">
      {sections.map((section, i) => (
        <div
          key={`experience-${i}`}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <ExperienceTitle title={section.title} />
          {section.details?.map((detail, j) => (
            <ExperienceDetail key={`${i}-${j}`} detail={detail} />
          ))}
          <div style={{ ...(i < sections.length - 1 && { height: "2vh" }) }} />
        </div>
      ))}
    </div>
  );
};

const ExperienceTitle = ({ title }) => {
  const isMobile = useMediaQuery();
  const domRef = useRef(null);
  const isVisible = useIsVisible(domRef);
  const { height: screenHeight } = useWindowDimensions();

  const calcStyle = () => ({
    height: 0.08 * screenHeight,
    minHeight: FONT_SIZES.xxxl,
    top: Math.max(90, 0.18 * screenHeight),
    ...(isMobile && {
      top: Math.max(90, 0.12 * screenHeight),
      fontSize: FONT_SIZES.s,
      height: 0.08 * screenHeight,
      minHeight: `calc(3 * ${FONT_SIZES.m})`,
    }),
  });

  return (
    <div
      className={`experience-title fade-in-section ${
        isVisible ? "is-visible" : ""
      }`}
      style={calcStyle()}
      ref={domRef}
    >
      {title}
    </div>
  );
};

const ExperienceDetail = ({ detail }) => {
  const isMobile = useMediaQuery();
  const domRef = useRef(null);
  const isVisible = useIsVisible(domRef);
  return (
    <div
      className={`experience-detail fade-in-section ${
        isVisible ? "is-visible" : ""
      }`}
      style={{
        fontSize: isMobile ? FONT_SIZES.xxs : FONT_SIZES.s,
      }}
      ref={domRef}
    >
      {"//"} {detail}
      <div style={{ height: "1vh" }} />
    </div>
  );
};

export default Experience;
