import React from "react";
import { FONT_SIZES } from "../../styles";

const Contact = () => {
  return (
    <div style={{ minHeight: "20rem", fontSize: FONT_SIZES.s }}>
      <a href="mailto:nicholascsward@gmail.com" style={{ display: "inline" }}>
        {"Please email me"}
      </a>{" "}
      if you'd like to chat about job opportunities, soup recipes, switching
      into tech, why you shouldn't pick skateboarding back up in your 30s, what
      it's like to work at Google, or if you need a good Chicago restaurant
      recommendation.
    </div>
  );
};

export default Contact;
