import React, { useState, useEffect } from "react";
import { FONT_SIZES, SPACING } from "../../styles";
import AboutMe from "./aboutMe";
import Contact from "./contact";
import Experience from "./experience";
import Projects from "./projects";
import { useWindowDimensions, useMediaQuery, clamp } from "../../utils";
import "./home.css";

const Home = () => {
  return (
    <>
      <Section title="TLDR" Component={AboutMe} />
      <Section title="Journey" Component={Experience} isBlack />
      <Section title="Projects" Component={Projects} />
      <Section title="Contact" Component={Contact} />
    </>
  );
};

const Section = ({
  Component,
  className = "home-section",
  title = "",
  isBlack = false,
}) => {
  const isMobile = useMediaQuery();

  return (
    <div
      className={className + (isBlack ? " home-section-black" : "")}
      style={{
        padding: SPACING.small,
        fontSize: isMobile ? FONT_SIZES.m : FONT_SIZES.xxl,
      }}
    >
      {title ? <SectionTitle title={title} /> : null}

      <Component />
    </div>
  );
};

const SectionTitle = ({ title = "" }) => {
  const [chars, setChars] = useState([""]);
  const isMobile = useMediaQuery();
  const { height: screenHeight } = useWindowDimensions();

  useEffect(() => {
    setChars(title.split(""));
  }, [title]);

  const calcStyle = () => ({
    minHeight: FONT_SIZES.xxxl,
    height: 0.1 * screenHeight,
    top: Math.max(45, 0.09 * screenHeight),
    ...(isMobile && {
      top: Math.max(45, 0.06 * screenHeight),
      height: 0.07 * screenHeight,
    }),
  });

  const getDuration = (i) => {
    const val = Math.max(((i + 1) * 15) / 100);
    return `${clamp(val, 0, 0.3)}s`;
  };

  return (
    <div className="home-section-title-container" style={calcStyle()}>
      <div className="home-section-title">
        {chars.map((char, i) => (
          <div
            className="wave"
            key={`title-char-${i}`}
            style={{
              animationDelay: `${Math.min(((i + 1) * 5) / 100, 0.7)}s`,
              animationDuration: getDuration(i),
            }}
          >
            {char}
          </div>
        ))}
      </div>
    </div>
  );
};
export default Home;
